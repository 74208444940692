const secToTime = (sec) => {
  if (!sec || sec < 0) return '00:00:00'
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - (hours * 3600)) / 60);
  const seconds = sec % 60;
  
  // Format the output as HH:MM:SS
  const formattedTime = `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  return formattedTime
}

export default secToTime
