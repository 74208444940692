import Aegis from 'aegis-web-sdk'

const createAegis = (() => {
  let instance = null

  return () => {
    if (!instance) {
      const searchParams = new URLSearchParams(window.location.search);
      const searchObject = Object.fromEntries(searchParams.entries());
      instance = new Aegis({
        id: 'p0lgnfkgPoQG1vKRd7', // 上报 id
        uin: searchObject.c,
        reportApiSpeed: true, // 接口测速
        spa: false, // spa 应用页面跳转的时候开启 pv 计算
        reportAssetSpeed: false, // 静态资源测速
        pagePerformance: false,
        hostUrl: 'https://rumt-zh.com',
        api: {
          apiDetail: true,
          retCodeHandler: (data, url, xhr) => {
            try {
              data = JSON.parse(data);
            } catch (e) {}
      
            return {
              isErr: data.code !== 200 && data.innerCode !== 10000,
              code: data.innerCode
            }
          }
        },
        beforeRequest: function(data) {
          // 用户取消请求不上报
          if (data.logType === 'log' && data.logs.level === '8' && data.logs.msg.includes('"code": "-1"')) {
            return false
          }
          // 用户取消请求不上报
          if (data.logType === 'log' && data.logs.level === '16' && (data.logs.msg.includes('fail:network interrupted error') || data.logs.msg.includes('fail:time out') || data.logs.msg.includes('ERR_CONNECTION_ABORTED') || data.logs.msg.includes('request:fail abort'))) {
            return false
          }
          if (data.logType === 'log' && data.logs.level === '32' && (data.logs.msg.includes('Uncaught TypeError: the given value is not a Promise') || data.logs.msg.includes("Uncaught TypeError: Cannot read properties of null (reading 'href')") || data.logs.msg === 'Script error. @ (:0:0)'))
          return data
        }
      });
    }
    return instance
  }
})()

export default createAegis
