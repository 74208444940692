import { Image, Popup, SafeArea, Stepper } from 'antd-mobile'
import { useEffect, useState } from 'react'
import styles from '../styles/sku.module.css';
import useData from '../hooks/use-data';
import { getPrime, descartes, PathFinder } from '../utils/sku'
import { goodsStore } from "../stores";

const SkuSelector = ({
  visible,
  onClose,
  specList,
  skuList,
  mainPic
}) => {
  const {
    state,
  } = goodsStore;
  const { jump } = useData()
  const [selectedList, setSelectedList] = useState([])
  const [selectedStr, setSelectedStr] = useState('')
  const [selected, setSelected] = useState([])
  const [canUseSku, setCanUseSku] = useState([])
  const [unDisabled, setUnDisabled] = useState([])
  const [valueInLabel, setValueInLabel] = useState({})
  const [num, setNum] = useState(1)
  const [image, setImage] = useState('')
  const [pathFinder, setPathFinder] = useState()
  const [specValueList, setSpecValueList] = useState([])

  const selectSku = (index, val) => {
    if (!unDisabled.includes(valueInLabel[`${specList[index].name}:${val.name}`])) {
      return
    }
    selectedList[index] = val.name
    setSelectedList([...selectedList])
    if (index === 0) {
      setImage(val.img)
    }
    const specValue = `${specList[index].name}:${val.name}`
    const prime = valueInLabel[specValue]


    const selectIndex = selected.indexOf(specValue);
    // 获取已经有的矩阵值，也就是如果某个位置是2，说明规格被选中

    const light = pathFinder.light;
    // 如果未选中则提供选中，如果选中移除
    if (selectIndex > -1) {
      pathFinder.remove(prime);
      selected.splice(selectIndex, 1);
      
      // 当前同一行中已经有被选择的了，比如如果你已经选择了男裤，那么再点击女裤的时候，就进入到这里，2表示已经被选中
    } else if (light[index].includes(2)) {
      // 如果同类型中，有规格被选中，则先移除选中的规格，
      // 获取需要移除的同行规格
      const removeType = specValueList[index][light[index].indexOf(2)];
      // 获取需要得到需要删除的规格对应的质数
      const removePrime = valueInLabel[removeType];
      // 移除已经被选中的规格
      pathFinder.remove(removePrime);
      selected.splice(selected.indexOf(removeType), 1);
      //移除同行后，添加当前选择规格
      pathFinder.add(prime);
      selected.push(specValue);
    } else {
      pathFinder.add(prime);
      selected.push(specValue);
    }

    // 更新不可选规格
    setSelected(selected)
    setUnDisabled(pathFinder.getWay().flat())
  }

  useEffect(() => {
    const flatSpecValueList = []
    const specValueList = []
    specList.forEach((spec, index) => {
      spec.propsDetailList.forEach((specValue) => {
        flatSpecValueList.push(`${spec.name}:${specValue.name}`)
      })
      specValueList.push(spec.propsDetailList.map(specValue => `${spec.name}:${specValue.name}`))
    })

    setSpecValueList(specValueList)
    const prime = getPrime(flatSpecValueList.length);
    const valueInLabel = {};
    // 质数对应的规格数
    flatSpecValueList.forEach((item, index) => {
      valueInLabel[item] = prime[index];
    });
    const way = specValueList.map((i) => {
      return i.map((ii) => valueInLabel[ii]);
    });
    const sku = descartes(specValueList).map((item) => {
      return {
        stock: skuList.findIndex(skuItem => skuItem.properties === item.join(';')) === -1 ? 0 : 1,
        // sku中保存的规格
        skuName: item,
        // 规格对应质数
        skuPrime: item.map((ii) => valueInLabel[ii]),
        properties: item.join(';')
      };
    });
    const canUseSku = sku.filter((item) => item.stock);
    const tmp = new PathFinder(
      way,
      canUseSku.map((item) => item.skuPrime)
    );
    setPathFinder(tmp)
    const unDisabled = tmp.getWay().flat();
    setCanUseSku(canUseSku)
    setUnDisabled(unDisabled)
    setValueInLabel(valueInLabel)
  }, [specList])

  useEffect(() => {
    if (skuList && skuList.length && pathFinder) {
      const properties = skuList[0].properties.split(';')
      for (let i = 0; i < properties.length; i++) {
        const [name, value] = properties[i].split(':')
        selectSku(i, specList[i].propsDetailList.find((specValue) => specValue.name === value))
      }
    }
  }, [skuList, pathFinder])

  useEffect(() => {
    let str = ''
    selectedList.forEach((item, index) => {
      str += specList[index].name + ':' + item
      if (index !== selectedList.length - 1) {
        str += ';'
      }
    })
    setSelectedStr(str)
  }, [selectedList, specList])
  
  
  return (
    <Popup
      visible={visible}
      showCloseButton
      onClose={onClose}
      onMaskClick={onClose}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      }}
    >
      <div className={styles.skuContainer}>
        <div className={styles.proWrap}>
          <Image className={styles.proImage} src={image || mainPic}></Image>
          <div className={styles.proInfo}>
          <div className={styles.proPrice}>￥{skuList?.find((sku) => sku.properties === selectedStr)?.price}</div>
          <div className={styles.quantity}>库存紧张</div>
          <div className={styles.skuTxt}>已选：{selectedList.join(' ')}</div>
          </div>
        </div>
        <div style={{ maxHeight: '50vh', height: 'fit-content', overflowY: 'scroll', margin: '10px 0' }}>
          {
            specList.map((spec, index) => {
              return (
                <div key={spec.name} className={styles.skuWrap}>
                  <div className={styles.skuTitle}>{ spec.name }</div>
                  <div className={styles.skuInfo}>
                    {
                      spec.propsDetailList.map((value) => {
                        return <div key={value.name} className={`${styles.skuValue} ${
                          selectedList[index] === value.name ? styles.selectedSku : unDisabled.includes(valueInLabel[`${specList[index].name}:${value.name}`]) ? styles.normalSku : styles.disabledSku
                        }`} onClick={() => selectSku(index, value)}>{value.name}</div>
                      })
                    }
                  </div>
                </div>
              )
            })
          }
          <div className={styles.fSb}>
            <div className={styles.buyCountTxt}>购买数量</div>
            <Stepper digits={0} min={1} style={{
              '--button-text-color': '#333333',
              '--height': '32.5px',
              '--input-width': '40px',
              '--button-background-color': '#f6f6f6',
              '--input-background-color': '#f6f6f6'
            }} value={num} onChange={setNum} />
          </div>
        </div>
        <div>
          <div className={styles.buyButton} onClick={() => jump((skuList?.find((sku) => sku.properties === selectedStr)?.skuId), num)}>
            {
              state.showJoinClub ? '立即参团' : '立即购买'
            }
          </div>
          <SafeArea position='bottom' />
        </div>
      </div>
    </Popup>
  )
}

export default SkuSelector
