import axios from 'axios';

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 10000
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'd8742596d62742b38297ebe40e415610'
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default service;
