import { proxy } from "valtio";

const state = proxy({
  goodsInfo: {},
  goodsCarouselList: [],
  goodsDetailPicList: [],
  evaluateList: [],
  answerList: [],
  buyerShowList: [],
  goodsPropsList: [],
  goodsSkuList: [],
  jumpLink: '',
  webLink: '',
  isError: false,
  showJoinClub: false
});

const store = {
  state,
  setGoodsInfo: (data) => {
    state.goodsInfo = data
  },
  setGoodsCarouselList: (data) => {
    state.goodsCarouselList = data
  },
  setGoodsDetailPicList: (data) => {
    state.goodsDetailPicList = data
  },
  setEvaluateList: (data) => {
    state.evaluateList = data
  },
  setAnswerList: (data) => {
    state.answerList = data
  },
  setBuyShowList: (data) => {
    state.buyerShowList = data
  },
  setGoodsPropsList: (data) => {
    state.goodsPropsList = data
  },
  setGoodsSkuList: (data) => {
    state.goodsSkuList = data
  },
  setJumpLink: (data) => {
    state.jumpLink = data
  },
  setWebLink: (data) => {
    state.webLink = data
  },
  setShowJoinClub: (data) => {
    state.showJoinClub = data
  }
};

export default store
