import styles from '../styles/group.module.css';
import { Swiper, Avatar, Divider } from 'antd-mobile'
import { useEffect, useState } from 'react';
import secToTime from '../utils/sec-to-time'

const Group = ({
  num,
  userList,
}) => {
  const [timeList, setTimeList] = useState([])
  const [spendTime, setSpendTime] = useState(0)
  const [millSeconds, setMillSeconds] = useState(0)
  useEffect(() => {
    const data = []
    if (timeList.length === userList.length) {
      return
    }
    userList.forEach((user) => {
      data.push(Math.floor(Math.random() * (24*3600 - 3600 + 1)) + 3600)
    })
    setTimeList(data)
    setSpendTime(0)
  }, [userList, timeList])

  useEffect(() => {
    const interval = setInterval(() => {
      setSpendTime(n => n + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setMillSeconds(n => n === 0 ? n = 9 : n - 1)
    }, 100)
    return () => clearInterval(interval)
  })

  return (
    <div className={styles.group}>
      <div className={styles.title}>
        <span>有</span>
        <span className={styles.joinNum}>{num}人</span>
        <span>正在拼团，参与可立即拼成</span>
      </div>
      <div className={styles.swiperWrap}>
        <Swiper direction='vertical' slideSize={33} trackOffset={0} style={{ '--height': '210px' }} loop stuckAtBoundary={false} indicator={() => null} autoplay allowTouchMove={false}>
        {userList.map((item, index) => {
          return <Swiper.Item key={index} className={styles.swiperItem}>
            <div className={styles.fSB}>
              <div className={styles.fIC}>
                <Avatar className={styles.avatar} style={{'--border-radius': '50%'}} src={item.avatar}></Avatar>
                <span className={styles.name}>{ item.name }</span>
              </div>
              <div className={styles.fIC}>
                <div className={styles.leftTimeWrap}>
                  <div>
                    <span>还差</span>
                    <span className={styles.joinNum}>1人</span>
                    <span>拼成</span>
                  </div>
                  <div className={styles.leftTime}>剩余{ secToTime(timeList[index] - spendTime) } : { millSeconds }</div>
                </div>
                <div className={styles.groupBtn}>立即参团</div>
              </div>
            </div>
            <Divider></Divider>
          </Swiper.Item>
        })}
        </Swiper>
      </div>
    </div>
  )
}

export default Group
