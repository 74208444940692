import { createHashRouter } from 'react-router-dom';

import Evalution from './pages/evalution';
import Answer from './pages/answer';
import BuyerShow from './pages/buyer-show';
import Goods from './pages/goods';

const router = createHashRouter([
  {
    path: '/',
    element: <Goods />
  },
  {
    path: 'answer',
    element: <Answer />
  },
  {
    path: 'buyer-show',
    element: <BuyerShow />
  },
  {
    path: 'evalution',
    element: <Evalution />
  }
]);

export default router;
