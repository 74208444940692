import service from '../request';
import { goodsStore } from "../stores";
import { useEffect } from 'react';
import { useSnapshot } from 'valtio'
import { Toast } from 'antd-mobile';

const useData = () => {
  const {
    state,
    setGoodsInfo,
    setGoodsCarouselList,
    setGoodsDetailPicList,
    setEvaluateList,
    setAnswerList,
    setBuyShowList,
    setGoodsPropsList,
    setGoodsSkuList,
    setJumpLink,
    setWebLink,
    setShowJoinClub
  } = goodsStore;
  const snap = useSnapshot(state)

  const getGoodsInfo = (data) => {
    Toast.show({
      icon: 'loading'
    })
      service
      .post('/api/getGoodsDetail', data)
      .then((res) => {
        setGoodsInfo(res.data.goodsVO);
        setGoodsCarouselList(res.data.goodsCarouselVO)
        setGoodsDetailPicList(res.data.goodsDetailPicVO)
        setEvaluateList(res.data.goodsEvaluateVO)
        setAnswerList(res.data.goodsFaqVO)
        const data = res.data.goodsShowVO.map((item) => {
          item.images = item.imgs.split(';');
          return item;
        });
        setBuyShowList(data);
        setGoodsPropsList(res.data.goodsSkuDetailVO?.goodsPropsList || [])
        setGoodsSkuList(res.data.goodsSkuDetailVO?.goodsSkuList || [])
        setShowJoinClub(!!res.data.showJoinClub)
        Toast.clear()
      }).catch((err) => {
        Toast.clear()
        Toast.show({
          icon: 'fail',
          content: '商品已下架'
        })
        state.isError = true
      })
  };

  const isMobileAlipay = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isAlipay = userAgent.indexOf('alipay') !== -1;
      const isMobile = /iphone|ipad|ipod|android/.test(userAgent);
      return isAlipay && isMobile;
  }

  const jump = (skuId, num) => {
    if (state.isError) {
      Toast.show({
        icon: 'fail',
        content: '商品已下架'
      })
      return
    }
    const searchParams = new URLSearchParams(window.location.search);
    const searchObject = Object.fromEntries(searchParams.entries());
    searchObject.r = skuId
    searchObject.n = num
    searchObject.g = state.goodsInfo.goodsId
    console.log('searchObject: ', searchObject);
    // if (isMobileAlipay()) {
    //   service
    //   .post('/api/getPromotionUrl', searchObject)
    // const url = `https://m.taobao.com/cart/order.html?itemId=${state.goodsInfo.goodsId}&item_num_id=${state.goodsInfo.goodsId}&_input_charset=utf-8&buyNow=true&v=0&skuId=${skuId}&quantity=${num}`
    // console.log('url: ', url);
    //   window.location.href = `https://m.taobao.com/cart/order.html?itemId=${state.goodsInfo.goodsId}&item_num_id=${state.goodsInfo.goodsId}&_input_charset=utf-8&buyNow=true&v=0&skuId=${skuId}&quantity=${num}`
    //   // const url = `https://m.taobao.com/cart/order.html?itemId=${state.goodsInfo.goodsId}&&item_num_id=${state.goodsInfo.goodsId}&_input_charset=utf-8&buyNow=true&v=0&skuId=${skuId}&quantity=${num}`
    //   // window.location.href = `tbopen://${encodeURI(url)}`
      // return
    // }
    if (state.jumpLink) {
      window.location.href = state.jumpLink
      return
    }
    if (state.webLink) {
      setTimeout(() => {
        window.location.href = state.webLink
      }, 300)
    }
    service
      .post('/api/getPromotionUrl', searchObject)
      .then((res) => {
        setJumpLink(res.data?.tbLink);
        setWebLink(res.data?.webLink)
        if (state.jumpLink) window.location.href = state.jumpLink
        setTimeout(() => {
          if (state.webLink) window.location.href = state.webLink
        }, 300)
      });
  }

  useEffect(() => {
    if (!snap.goodsInfo.goodsId) {
      const searchParams = new URLSearchParams(window.location.search);
      const searchObject = Object.fromEntries(searchParams.entries());
      console.log('searchObject: ', searchObject);
      getGoodsInfo(searchObject);
    }
  }, []);

  return {
    jump
  }
}

export default useData
