import { useState } from 'react';
import { Image, ImageViewer } from 'antd-mobile';

export default function ImagePreview(props) {
  const [visible, setVisible] = useState(false);
  const { width = 100, height = 100, mode = 'auto', style = {
    borderRadius: '6px',
  }, intercept } = props;
  return (
    <div className="image-review">
      <Image
        src={props.imageSrc}
        fit={mode}
        style={style}
        width={width}
        height={height}
        onClick={(event) => intercept(event, () => {
          setVisible(true);
          event.preventDefault()
        })}
      />
      <ImageViewer
        image={props.imageSrc}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      />
    </div>
  );
}
