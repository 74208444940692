import { Ellipsis, Image, Avatar } from 'antd-mobile';
import { useState, useEffect } from 'react';
import service from '../request';
import ImagePreview from '../components/imagePreview';
import styles from '../styles/evalution.module.css';
import EmptyCp from '../components/emptyCp';
import { goodsStore } from "../stores";
import { useSnapshot } from 'valtio'
import SkuSelector from '../components/sku-selector';
import useData from '../hooks/use-data';

function Evalution() {
  const {
    state
  } = goodsStore;
  const snap = useSnapshot(state)
  const { jump } = useData()
  const [skuVisible, setSkuVisible] = useState()

  const buy = () => {
    if (state.goodsSkuList && state.goodsPropsList && state.goodsSkuList.length && state.goodsPropsList.length) {
      setSkuVisible(true)
    } else {
      jump(state.goodsInfo.skuId, 1)
    }
  }

  const globalBuy = () => {
    if (state.goodsInfo.clickAnywhereToBuy) {
      buy();
    }
  }

  return (
    <>
      {
        !state.goodsInfo.evaluatePhotoStyle ? <div className={styles.evalutionPage} onClick={globalBuy}>
        <div className="goods-info">
          {!!snap.goodsCarouselList.length && <ImagePreview imageSrc={snap.goodsCarouselList[0].url} width={128} height={128} />}
          <div className="goods-wrap">
            <p>{snap.goodsInfo.title}</p>
            <div className={styles.goodsPrice}>
              <span>活动价￥{snap.goodsInfo.price}</span>
              <span onClick={buy}>立即购买</span>
            </div>
          </div>
        </div>

        <div className={styles.evaluationList}>
          {snap.evaluateList.length === 0 ? (
            <EmptyCp />
          ) : (
            snap.evaluateList.map((evaluate, index) => (
              <div className={styles.evaluationItem} key={index}>
                <div className="evaluation-item-head">
                  <div className="user-avatar">
                    <Avatar src={evaluate.headUrl} style={{ '--size': '28px', borderRadius: 14 }} />
                  </div>
                  <div className="user-info">
                    <span>{evaluate.nick}</span>
                    <span>{evaluate.evaluateTime}</span>
                  </div>
                </div>
                <div className={styles.evaluationContainer}>
                  <div className={styles.evaluationTxt}>
                    <Ellipsis direction="end" rows={4} expandText="展开" collapseText="收起" content={evaluate.content} />
                  </div>
                  <div className={styles.evaluationPic}>
                    {evaluate.imgs?.split(';')?.map((image, index) => {
                      return (
                        <div className="evaluation-pics-item" key={index}>
                          <ImagePreview imageSrc={image} width={'20vw'} height={'20vw'} style={{ borderRadius: 8 }} mode='fill' />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div> : <div className='fc'>
        {
          snap.goodsInfo.evaluateLargeImg?.split(';')?.filter(image => image)?.map((image, index) => {
            return <Image key={index} src={image} width='100%' height='auto' fit='fill' onClick={buy}></Image>
          })
        }
      </div>
      }
      <SkuSelector visible={skuVisible} specList={state.goodsPropsList} onClose={ () => setSkuVisible(false) } mainPic={(state.goodsCarouselList || [])[0]?.url} skuList={state.goodsSkuList} ></SkuSelector>
    </>
  );
}

export default Evalution;
