import { Ellipsis, InfiniteScroll, Image } from 'antd-mobile';
import { useState, useEffect } from 'react';
import ImagePreview from '../components/imagePreview';
import styles from '../styles/buyer.module.css';
import service from '../request';
import { goodsStore } from "../stores";
import { useSnapshot } from 'valtio'
import SkuSelector from '../components/sku-selector';
import useData from '../hooks/use-data';

function BuyerShow() {
  const {
    state
  } = goodsStore;

  const snap = useSnapshot(state)
  const { jump } = useData()
  const [skuVisible, setSkuVisible] = useState()

  const buy = () => {
    if (state.goodsSkuList && state.goodsPropsList && state.goodsSkuList.length && state.goodsPropsList.length) {
      setSkuVisible(true)
    } else {
      jump(state.goodsInfo.skuId, 1)
    }
  }

  function computedGap(array) {
    // if (array.length === 1) {
    //   return `repeat(1, 1fr)`;
    // } else if (4 > array.length > 1) {
    //   return `repeat(2, 1fr)`;
    // } else if (array.length > 4) {
    //   return `repeat(2, 1fr)`;
    // }

    return `repeat(4, 1fr)`;
  }

  const globalBuy = () => {
    if (state.goodsInfo.clickAnywhereToBuy) {
      buy();
    }
  }
  
  return (
    <>
      {
        !state.goodsInfo.showPhotoStyle ? <div className={styles.buyShow} onClick={globalBuy}>
        <div className="goods-info">
          {!!snap.goodsCarouselList.length && <ImagePreview imageSrc={snap.goodsCarouselList[0].url} width={128} height={128} />}
          <div className="goods-wrap">
            <p>{snap.goodsInfo.title}</p>
            <div className={styles.goodsPrice}>
              <span>活动价￥{snap.goodsInfo.price}</span>
              <span onClick={buy}>立即购买</span>
            </div>
          </div>
        </div>
        <div className={styles.buyerList}>
          {snap.buyerShowList.map((item, index) => (
            <div className={styles.buyerItem} key={item.id}>
              <div className={styles.buyerHead}>
                <Image src={item.headUrl} width={24} height={24} fit="cover" style={{ borderRadius: 12 }} />
                <span>{item.nick}</span>
                <span>{item.showTime}</span>
              </div>
              <div className={styles.buyerPic} style={{ gridTemplateColumns: computedGap(item.images) }}>
                {item.images.map((img, index) => {
                  return <ImagePreview imageSrc={img} key={index} width={'20vw'} height={'20vw'} style={{ borderRadius: 8 }} mode='fill' />;
                })}
              </div>
              <div className={styles.buyerTxt}>
                <Ellipsis direction="end" expandText="展开" collapseText="收起" rows={3} content={item.content} />
              </div>
            </div>
          ))}
        </div>
      </div> : <div className='fc'>
        {
          snap.goodsInfo.showLargeImg?.split(';')?.filter(image => image)?.map((image, index) => {
            return <Image key={index} src={image} width='100%' height='auto' fit='fill' onClick={buy}></Image>
          })
        }
      </div>
      }
      <SkuSelector visible={skuVisible} specList={state.goodsPropsList} onClose={ () => setSkuVisible(false) } mainPic={(state.goodsCarouselList || [])[0]?.url} skuList={state.goodsSkuList} ></SkuSelector>
    </>
  );
}

export default BuyerShow;
