import { Image, Swiper, Divider, ImageViewer, Ellipsis, Avatar, Empty } from 'antd-mobile';
import { RightOutline, StarFill, StarOutline, QuestionCircleOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSnapshot } from 'valtio'

import ImagePreview from '../components/imagePreview';
import SkuSelector from '../components/sku-selector';
import Group from '../components/group';
// import service from '../request';
import { goodsStore } from "../stores";
import useData from '../hooks/use-data';
import service from '../request';

function EmptyCp() {
  return (
    <Empty
      style={{ padding: '24px 0' }}
      image={
        <QuestionCircleOutline
          style={{
            color: 'var(--adm-color-light)',
            fontSize: 36
          }}
        />
      }
      description="暂无数据"
    />
  );
}

function Goods() {
  const {
    state,
  } = goodsStore;
  const snap = useSnapshot(state)
  const { jump } = useData()
  const navigate = useNavigate();

  const toEvalution = function () {
    navigate('/evalution');
  };

  const toAnswer = function () {
    navigate('/answer');
  };

  const toBuyer = function () {
    navigate('/buyer-show');
  };

  // 活动倒计时
  const [timeNumber, setTimeNumber] = useState(3600000);
  // const [timer, setTimer] = useState(0);
  const [timeObj, setTimeObj] = useState([59, 59, 9]);
  useEffect(() => {
    if (timeNumber > 0) {
      let intId = setInterval(() => {
        setTimeNumber(timeNumber - 100);
        let m = Math.floor((timeNumber / 1000 / 60) % 60); // 分
        let s = Math.floor((timeNumber / 1000) % 60); // 秒
        let ms = Math.floor(timeNumber % 1000)
          .toString()
          .slice(0, 1); // 毫秒

        if (m < 10) {
          m = '0' + m;
        }
        if (s < 10) {
          s = '0' + s;
        }
        setTimeObj([m, s, ms]);
      }, 100);
      return () => clearInterval(intId);
    }
  }, [timeNumber]);

  const [buyShowImages, setBuyShowImages] = useState([])

  useEffect(() => {
    const data = [];
    snap.buyerShowList.forEach((item) => {
      item.imgs.split(';').forEach((img) => {
        if (data.length < 3) {
          data.push(img);
        }
      });
    });
    setBuyShowImages(data);

  }, [snap.buyerShowList])

  // 轮播图点击事件
  const [visible, setVisible] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const swiperHandle = (index) => {
    setVisible(true);
    setSwiperIndex(index);
  };

  const clickShop = () => {
    window.location.href = snap.goodsInfo.shopUrl
  }

  const clickCS = () => {
    window.location.href = snap.goodsInfo.customerServiceUrl
  }

  const [isCollected, setIsCollected] = useState(false)

  const clickCollect = () => {
    setIsCollected(!isCollected)
  }

  const [skuVisible, setSkuVisible] = useState()

  const buy = () => {
    if (state.goodsSkuList && state.goodsPropsList && state.goodsSkuList.length && state.goodsPropsList.length) {
      setSkuVisible(true)
    } else {
      jump(state.goodsInfo.skuId, 1)
    }
  }

  const [userList, setUserList] = useState([])
  const [groupNum, setGroupNum] = useState([])

  // @params fun 为点击按钮的回调函数
  const intercept = (event, fun) => {
    if (state.goodsInfo.clickAnywhereToBuy) {
      event.preventDefault()
    } else {
      fun()
    }
  }

  const globalBuy = () => {
    if (state.goodsInfo.clickAnywhereToBuy) {
      buy();
    }
  }

  useEffect(() => {
    service.get('/api/joinClubResource?num=30').then((res) => {
      const data = []
      res.data.forEach((item) => {
        data.push({
          name: Object.keys(item)[0],
          avatar: item[Object.keys(item)[0]]
        })
      })
      setUserList(data)
      setGroupNum(Math.ceil(Math.random() * 90) + 10)
    })
  }, [])

  return (
    <div className="goods-page" onClick={globalBuy}>
      <section className="page-swiper">
        <Swiper autoplay>
          {snap.goodsCarouselList.length ? (
            snap.goodsCarouselList.map((image, index) => {
              return (
                <Swiper.Item key={index}>
                  <div className="goods-pic">
                    <Image src={image.url} onClick={(event) => intercept(event, () => swiperHandle(index))} className='page-swiper' />
                  </div>
                </Swiper.Item>
              );
            })
          ) : (
            <Swiper.Item></Swiper.Item>
          )}
        </Swiper>
        <ImageViewer.Multi
          images={snap.goodsCarouselList.map(image => image.url)}
          visible={visible}
          defaultIndex={swiperIndex}
          onClose={() => {
            setVisible(false);
            setSwiperIndex(1);
          }}
        />
      </section>

      <section className="goods-price">
        <div className="active-price">
          <div className="price-wrap">
            <div className="price">
              <span>活动价￥</span>
              <span>{snap.goodsInfo.price}</span>
            </div>
            <div className="price-offers">
              <span>限时直降&nbsp;{snap.goodsInfo.couponAmount?.toFixed(2)}元</span>
            </div>
          </div>
          <div className="price-num">
            <div className="original-price">
              <span>即将恢复</span>
              {snap.goodsInfo.price && <span>￥{(snap.goodsInfo.couponAmount + snap.goodsInfo.price).toFixed(2)}</span>}
            </div>
            <Divider direction="vertical" />
            <span>仅限500件</span>
          </div>
        </div>
        <div className="limit-price">
          <span>限时活动</span>
          <div className="price-countdown-time">
            <span>{timeObj[0]}</span>
            <span>:</span>
            <span>{timeObj[1]}</span>
            <span>:</span>
            <span>{timeObj[2]}</span>
          </div>
        </div>
      </section>

      <section className="goods-title">
        <span>
          <span>促销</span>
          {/* <Ellipsis direction="end" rows={2} content={'XMAN+库存304不锈钢咖啡勺精致长柄搅拌勺可爱甜品奶茶小汤勺尾货'} /> */}
          <p>{snap.goodsInfo.title}</p>
        </span>
      </section>

      <section className="goods-sku">
        <div className="goods-sku-item" onClick={buy}>
          <span>选择</span>
            {
              state.goodsPropsList && state.goodsPropsList.length ? <div className='goods-sku-images-wrap'>
                <div className='goods-sku-images'>
                {state.goodsPropsList[0].propsDetailList.map((item, index) => <Image src={item.img || (state?.goodsCarouselList || [])[0]?.url} key={index} className='goods-sku-image'></Image>)}
                </div><span className='goods-sku-type'>共{state.goodsSkuList.length}种套餐类型可选<RightOutline></RightOutline></span>
              </div> : 
              <div className="goods-sku-specification">已选默认规格</div>
            }
        </div>
        <div className="goods-sku-item">
          <span>发货</span>
          <div className="goods-sku-shipping">
            <span>快递：免运费</span>
            <span>现在付款，今日发货</span>
          </div>
        </div>
        <div className="goods-sku-item">
          <span>保障</span>
          <div className="goods-sku-promise">
            <span>正品保障·七天无理由退换货</span>
            <span>送货上门的配送服务</span>
          </div>
        </div>
      </section>

      {
        state.showJoinClub && !!userList.length && <div className='white-block' onClick={buy}>
        <Group num={groupNum} userList={userList}></Group>
        </div>
      }

      <div className='activity-wrap'>
        {
        (snap.evaluateList.length !== 0 || snap.goodsInfo.evaluatePhotoStyle === 1) &&
        <section className="goods-evaluation">
        <div className="evaluation-wrap">
          <div className="evaluation-wrap-header" onClick={(event) => intercept(event, toEvalution)}>
            <span>活动点评{ state.goodsInfo.goodsEvaluateNum ? ` (${state.goodsInfo.goodsEvaluateNum})` : '' }</span>
            <span>
              查看全部
              <RightOutline></RightOutline>
            </span>
          </div>
          {
            !snap.goodsInfo.evaluatePhotoStyle && <>
              {snap.evaluateList.length === 0 ? (
                <EmptyCp />
              ) : (
                <div className="evaluation-list">
                  {snap.evaluateList.slice(0, 1).map((evaluate) => {
                    return (
                      <div className="evaluation-item" key={evaluate.id}>
                        <div className="evaluation-item-head" onClick={(event) => intercept(event, toEvalution)}>
                          <div className="user-avatar">
                            <Avatar src={evaluate.headUrl} style={{ '--size': '40px', borderRadius: '50%' }} />
                          </div>
                          <div className="user-info">
                            <span>{evaluate.nick}</span>
                            <span>{evaluate.evaluateTime}</span>
                          </div>
                        </div>
                        <div className="evaluation-container">
                          <div className="evaluation-txt" onClick={(event) => intercept(event, toEvalution)}>
                            <Ellipsis direction="end" rows={4} content={evaluate.content} />
                          </div>
                          { evaluate.imgs && <div className="evaluation-pic">
                              {
                                evaluate.imgs.split(';').slice(0, 4).map(img => {
                                  return  <ImagePreview imageSrc={img} width='21vw' height='21vw' style={{ borderRadius: 8, }} mode='fill' intercept={intercept} />
                                })
                              }
                              {
                                [...Array(4 - evaluate.imgs.split(';').slice(0, 4).length)].map((item) => <div style={{width: '21vw'}} key={item}></div>)
                              }
                          </div>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          }
        </div>
      </section>
      }

      {
        snap.goodsInfo.evaluatePhotoStyle === 1 && snap.goodsInfo.evaluateHomePageImg && <Image src={snap.goodsInfo.evaluateHomePageImg} width='100%' height='auto' fit='fill' onClick={(event) => snap.goodsInfo.evaluateLargeImg ? intercept(event, toEvalution) : buy()}></Image>
      }

      {
        (snap.answerList.length !== 0 || snap.goodsInfo.faqPhotoStyle === 1) && 
        <section className="goods-evaluation" onClick={(event) => intercept(event, toAnswer)}>
        <div className="evaluation-wrap">
          <div className="evaluation-wrap-header">
            <span>活动答疑{ state.goodsInfo.goodsFaqNum ? ` (${state.goodsInfo.goodsFaqNum})` : '' }</span>
            <span>
              查看全部
              <RightOutline></RightOutline>
            </span>
          </div>
          {
            !snap.goodsInfo.faqPhotoStyle && 
            <div className="evaluation-answer">
              {snap.answerList.length === 0 ? (
                <EmptyCp />
              ) : (
                snap.answerList.slice(0, 1).map((item, index) => {
                  return (
                    <div className="evaluation-answer-item" key={item.id}>
                      <div className="qestion">
                        <span>问</span>
                        <Ellipsis direction="end" content={item.ask} />
                      </div>
                      <div style={{color: 'rgba(0,0,0,.45)'}}>{snap.answerList.length}个回答</div>
                      {/* <div className="answer">
                        <span>答</span>
                        <Ellipsis direction="end" rows={2} content={item.answer} />
                      </div> */}
                    </div>
                  );
                })
              )}
            </div>
          }
        </div>
      </section>
      }

      {
        snap.goodsInfo.faqPhotoStyle === 1 && snap.goodsInfo.faqHomePageImg && <Image src={snap.goodsInfo.faqHomePageImg} width='100%' height='auto' fit='fill' onClick={(event) => snap.goodsInfo.faqLargeImg ? intercept(event, toAnswer) : buy()}></Image>
      }

      {
        (snap.buyerShowList.length !== 0 || snap.goodsInfo.showPhotoStyle === 1) &&
        <section className="goods-evaluation">
        <div className="buyer-show">
          <div className="evaluation-wrap-header" onClick={(event) => intercept(event, toBuyer)}>
            <span>买家秀{ state.goodsInfo.goodsShowNum ? ` (${state.goodsInfo.goodsShowNum})` : '' }</span>
            <span>
              查看全部
              <RightOutline></RightOutline>
            </span>
          </div>

          {
            !snap.goodsInfo.showPhotoStyle && <>
              {snap.buyerShowList.length === 0 ? (
                <EmptyCp />
              ) : (
                <div className="buyer-pic">
                  {buyShowImages.map((image, index) => {
                    return <ImagePreview key={index} imageSrc={image} width='30vw' height='30vw' style={{ borderRadius: 8 }} mode='fill' intercept={intercept} />;
                  })}
                  {
                    [...Array(3 - buyShowImages.length)].map((item) => <div style={{width: '30vw'}} key={item}></div>)
                  }
                </div>
              )}
            </>
          }
        </div>
      </section>
      }

      {
        snap.goodsInfo.showPhotoStyle === 1 && snap.goodsInfo.showHomePageImg && <Image src={snap.goodsInfo.showHomePageImg} width='100%' height='auto' fit='fill' onClick={(event) => snap.goodsInfo.showLargeImg ? intercept(event, toBuyer) : buy()}></Image>
      }
      </div>

      <section className="goods-pic">
        <span>商品详情</span>
        {snap.goodsDetailPicList.length === 0 ? (
          <EmptyCp />
        ) : (
          snap.goodsDetailPicList.map((pic) => {
            return <Image src={pic.url} key={pic.id} width={'100%'} height={'auto'} fit="fill" />;
          })
        )}
      </section>

      <section className="purchase">
        {snap.goodsInfo.shopUrl && <div className='fc-ac icon-item' onClick={(event) => intercept(event, clickShop)}>
          <Image className='bottom-icon' src='https://saasobs.xmonecode.com/fe/DAcl2qcA85ZonFk4vf38u-10.png'></Image>
          <div className='icon-text'>店铺</div>
        </div>}
        {snap.goodsInfo.customerServiceUrl && <div className='fc-ac icon-item' onClick={(event) => intercept(event, clickCS)}>
          <Image className='bottom-icon' src='https://saasobs.xmonecode.com/fe/guwZw-cxX_A4bpWZpnWH--11.png'></Image>
          <div className='icon-text'>客服</div>
        </div>}
        {snap.goodsInfo.collectionStatus && <div className='fc-ac icon-item' onClick={(event) => intercept(event, clickCollect)}>
          <Image className='bottom-icon' src={isCollected ? 'https://saasobs.xmonecode.com/fe/4EE53PWp3YB3ZnxFKKO92-13.png' : 'https://saasobs.xmonecode.com/fe/QAFEgYyYLUF57f0_LnPR3-12.png'}></Image>
          <div className='icon-text'>{ isCollected ? '已收藏' : '收藏' }</div>
        </div>}
        <div className="purchase-btn">
          <span onClick={buy}>{
            state.showJoinClub ? '参团购买' : '点击购买'
          }</span>
        </div>
      </section>
      {
        !!state.goodsPropsList.length && !!state.goodsSkuList.length && <SkuSelector visible={skuVisible} specList={state.goodsPropsList} onClose={ () => setSkuVisible(false) } mainPic={(state.goodsCarouselList || [])[0]?.url} skuList={state.goodsSkuList} ></SkuSelector>
      }
      {
        !!state.goodsInfo.goodsId && <iframe className='tb-iframe' width={1} height={1} src={'https://item.taobao.com/item.htm?id=' + state.goodsInfo.goodsId} title='商品详情'></iframe>
      }
    </div>
  );
}

export default Goods;
